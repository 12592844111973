import { easings } from "@react-spring/web";
import { useCallback } from "react";
import { animated, useSprings } from "react-spring";
import styled from "styled-components";
import {
  BG_COLOR,
  BORDER_COLOR,
  CONTAINER_WIDTH_MEDIUM,
  TEXT_COLOR,
} from "./constants";
import { grainPattern } from "./styles";

const name = [
  { letter: "d" },
  { letter: "a" },
  { letter: "n" },
  { letter: "i" },
  { letter: "e" },
  { letter: "l" },
  { letter: "g" },
  { letter: "y" },
  { letter: "l" },
  { letter: "l" },
  { letter: "e" },
  { letter: "n" },
  { letter: "s" },
  { letter: "a" },
  { letter: "n" },
  { letter: "d" },
];

const altName = [
  { letter: "c" },
  { letter: "o" },
  { letter: "m" },
  { letter: "p" },
  { letter: "u" },
  { letter: "t" },
  { letter: "e" },
  { letter: "r" },
  { letter: "e" },
  { letter: "m" },
  { letter: "o" },
  { letter: "t" },
  { letter: "i" },
  { letter: "o" },
  { letter: "n" },
  { letter: "s" },
];

const Header = () => {
  const [springs, setSprings] = useSprings(16, (i) => ({
    scale: 1,
    opacity: 1,
  }));

  const [secondarySprings, setSecondarySprings] = useSprings(16, (i) => ({
    scale: 0.3,
    opacity: 0,
  }));

  const onMouseOver = useCallback(() => {
    setSprings.start((i) => ({
      scale: 0.3,
      opacity: 0,
      delay: i * 20,
      config: {
        easing: easings.easeOutExpo,
        duration: 600,
      },
    }));

    setSecondarySprings.start((i) => ({
      scale: 1,
      opacity: 1,
      delay: i * 20,
      config: {
        easing: easings.easeOutExpo,
        duration: 600,
      },
    }));
  }, [setSprings, setSecondarySprings]);

  const onMouseLeave = useCallback(() => {
    setSprings.start((i) => ({
      scale: 1,
      opacity: 1,
      delay: i * 20,
      config: {
        easing: easings.easeOutExpo,
        duration: 600,
      },
    }));

    setSecondarySprings.start((i) => ({
      scale: 0.3,
      opacity: 0,
      delay: i * 20,
      config: {
        easing: easings.easeOutExpo,
        duration: 600,
      },
    }));
  }, [setSprings, setSecondarySprings]);

  return (
    <Wrapper>
      <Container>
        <Content onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
          <TextContainer>
            <Title>
              {name.map((o, i) => (
                <animated.span
                  key={i}
                  style={{
                    display: "inline-block",
                    marginRight: i === 5 ? 5 : 0,
                    ...springs[i],
                  }}
                >
                  {o.letter}
                </animated.span>
              ))}
            </Title>
            <SecondaryTitle>
              {altName.map((o, i) => (
                <animated.span
                  key={i}
                  style={{
                    display: "inline-block",
                    marginRight: i === 7 ? 5 : 0,
                    ...secondarySprings[i],
                  }}
                >
                  {o.letter}
                </animated.span>
              ))}
            </SecondaryTitle>
          </TextContainer>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
`;

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH_MEDIUM}px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  padding: 0;

  @media (min-width: ${CONTAINER_WIDTH_MEDIUM}px) {
    padding: 0 20px;
  }
`;

const Content = styled.div`
  position: relative;
  border: 1px solid ${BORDER_COLOR};
  border-top: none;
  background-color: ${BG_COLOR};
  pointer-events: all;
  border-radius: 0 0 5px 0;

  @media (min-width: ${CONTAINER_WIDTH_MEDIUM}px) {
    border-radius: 0 0 5px 5px;
  }
`;

const TextContainer = styled.div`
  position: relative;
  padding: 6px 20px;
  overflow: hidden;

  &::after {
    ${grainPattern}
  }

  @media (min-width: ${CONTAINER_WIDTH_MEDIUM}px) {
    padding: 10px 20px;
  }
`;

const Title = styled.h1`
  cursor: default;
  color: ${TEXT_COLOR};
  margin: 0;
  font-size: 16px;
`;

const SecondaryTitle = styled.h1`
  cursor: default;
  color: ${TEXT_COLOR};
  margin: 0;
  font-size: 16px;
  position: absolute;
  top: 6px;
  left: 20px;

  @media (min-width: ${CONTAINER_WIDTH_MEDIUM}px) {
    top: 10px;
  }
`;
