import { useMemo, useRef, useState } from "react";
import { GenerativeToken } from "./types";
import {
  BG_COLOR,
  BORDER_COLOR,
  CONTAINER_WIDTH_MEDIUM,
  CONTAINER_WIDTH_SMALL,
  SHADOW_COLOR,
  TEXT_COLOR,
} from "./constants";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import { ASSETS } from "./App";
import Thumbnail from "./Thumbnail";

export function getGeneratedUrl(uri: string) {
  const gateway = "https://gateway.fxhash.xyz/ipfs/";
  return gateway + uri.substring(7);
}

const GET_PROJECT_INFO = gql`
  query Query($generativeTokenId: Float) {
    generativeToken(id: $generativeTokenId) {
      objkts {
        metadata
      }
    }
  }
`;

interface GraphResponse {
  generativeToken: {
    objkts: GenerativeToken[];
  };
}

const descriptionRegex = /^(.*?)[.]\s/;

const openLiveVersion = (uri: string, name: string) => {
  const hasCorrectPath = uri.indexOf("/?");

  if (hasCorrectPath > -1) {
    window.open(getGeneratedUrl(uri), name, "width=600,height=600");
  } else {
    const output = uri.replace(/\?/g, "/?");
    window.open(getGeneratedUrl(output), name, "width=600,height=600");
  }
};

const Project = ({
  id,
  name,
  metadata,
  objktsCount,
  index,
}: GenerativeToken & { index: number }) => {
  const { data } = useQuery<GraphResponse>(GET_PROJECT_INFO, {
    variables: {
      generativeTokenId: id,
    },
  });

  const { objkts } = data?.generativeToken || { objkts: [] };
  const { displayUri, description } = metadata;
  const [assetLoaded, setAssetLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const shuffledObjects = useMemo(
    () =>
      objkts.length
        ? objkts
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
        : new Array(14).fill({}),
    [objkts]
  );

  const shortDescription = useMemo(
    () => descriptionRegex.exec(description)?.[0] || "",
    [description]
  );

  const videoAsset = useMemo(() => ASSETS.find((o) => o.id === id), [id]);

  return (
    <Wrapper>
      <div>
        <InfoTop index={index}>
          <Title>{name}</Title>
          <Meta index={index}>{`${objktsCount} editions`}</Meta>
        </InfoTop>
        <Description index={index}>{shortDescription}</Description>
        <Link href={`https://www.fxhash.xyz/generative/${id}`} target="_blank">
          #read more
        </Link>
      </div>

      <ScrollContainer index={index}>
        <ScrollContent index={index}>
          <Grid index={index}>
            {videoAsset ? (
              <ImageContainer
                index={index}
                onClick={() => openLiveVersion(metadata.artifactUri, name)}
              >
                <Video
                  ref={videoRef}
                  autoPlay
                  muted
                  loop
                  playsInline
                  isLoaded={assetLoaded}
                  onCanPlay={() => {
                    setAssetLoaded(true);
                  }}
                >
                  <source src={videoAsset.video} type="video/mp4" />
                </Video>
              </ImageContainer>
            ) : (
              <ImageContainer
                index={index}
                onLoad={() => setAssetLoaded(true)}
                onClick={() => openLiveVersion(metadata.artifactUri, name)}
              >
                <Image
                  isLoaded={assetLoaded}
                  src={getGeneratedUrl(displayUri)}
                  alt={metadata.name}
                ></Image>
              </ImageContainer>
            )}

            {shuffledObjects
              .map(({ value }) => value)
              .filter((o, i) => i <= 13)
              .map((object, i) => (
                <Thumbnail
                  key={i}
                  metadata={object?.metadata}
                  name={name}
                  index={index}
                  onClick={() =>
                    openLiveVersion(object?.metadata.artifactUri, name)
                  }
                />
              ))}
          </Grid>
        </ScrollContent>
      </ScrollContainer>
    </Wrapper>
  );
};

export default Project;

const Wrapper = styled.section`
  color: ${TEXT_COLOR};
  margin-bottom: 50px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:nth-of-type(even) {
    text-align: right;
    align-items: flex-end;
  }
`;

const InfoTop = styled.div<{ index: number }>`
  display: flex;
  align-items: ${({ index }) => (index % 2 === 0 ? "flex-start" : "flex-end")};
  flex-direction: column;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    align-items: center;
    flex-direction: ${({ index }) => (index % 2 === 0 ? "row" : "row-reverse")};
  }
`;

const Title = styled.h2`
  font-size: 24px;
  margin: 0;
  text-decoration: underline;
`;

const Meta = styled.p<{ index: number }>`
  font-size: 16px;
  border: 1px solid ${BORDER_COLOR};
  display: inline-block;
  padding: 7px 5px 5px;
  margin-bottom: 0;
  margin-top: 10px;
  border-radius: 5px;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    margin-top: 0;
    margin-left: ${({ index }) => (index % 2 === 0 ? "15px" : 0)};
    margin-right: ${({ index }) => (index % 2 === 0 ? 0 : "15px")};
  }
`;

const Description = styled.p<{ index: number }>`
  max-width: 500px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: ${({ index }) => (index % 2 === 0 ? 0 : "auto")};
`;

const Link = styled.a`
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  color: ${TEXT_COLOR};
`;

const ScrollContainer = styled.div<{ index: number }>`
  width: 100vw;
  display: flex;
  margin-left: ${({ index }) =>
    index % 2 === 0
      ? `min(-20px, calc((${CONTAINER_WIDTH_MEDIUM}px - 40px - 100vw)/2))`
      : 0};
  margin-right: ${({ index }) =>
    index % 2 === 0
      ? 0
      : `min(-20px, calc((${CONTAINER_WIDTH_MEDIUM}px - 40px - 100vw)/2))`};
  direction: ${({ index }) => (index % 2 === 0 ? "ltr" : "rtl")};
  overflow-x: auto;
  padding: 30px 0;
  padding-left: ${({ index }) =>
    index % 2 === 0
      ? `max(20px, calc((100vw - (${CONTAINER_WIDTH_MEDIUM}px - 40px)) / 2))`
      : 0};
  padding-right: ${({ index }) =>
    index % 2 === 0
      ? 0
      : `max(20px, calc((100vw - (${CONTAINER_WIDTH_MEDIUM}px - 40px)) / 2))`};
  border-bottom: 1px solid ${BORDER_COLOR};
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollContent = styled.div<{ index: number }>`
  margin-left: ${({ index }) => (index % 2 === 0 ? 0 : "20px")};
  margin-right: ${({ index }) => (index % 2 === 0 ? "20px" : 0)};
`;

const ImageContainer = styled.div<{ index: number }>`
  cursor: pointer;
  background-color: ${BG_COLOR};
  grid-row: 1 / 3;
  box-shadow: ${({ index }) =>
    index % 2 === 0
      ? `4px 4px 10px 0px ${SHADOW_COLOR}80`
      : `-4px 4px 10px 0px ${SHADOW_COLOR}80`};
  height: 260px;
  width: 260px;
  border-radius: 5px;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    height: 420px;
    width: 420px;
  }
`;

const Image = styled.img<{ isLoaded: boolean }>`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  transition: opacity 0.2s;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const Video = styled.video<{ isLoaded: boolean }>`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  transition: opacity 0.2s;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const Grid = styled.div<{ index: number }>`
  display: grid;
  direction: ${({ index }) => (index % 2 === 0 ? "ltr" : "rtl")};
  grid-auto-flow: column;
  grid-gap: 10px;
  grid-auto-rows: 125px;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    grid-auto-rows: 200px;
    grid-gap: 20px;
  }
`;
