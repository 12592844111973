import styled, { keyframes } from "styled-components";
import { BG_COLOR, BORDER_COLOR } from "./constants";
import { grainPattern } from "./styles";

export const Loader = () => {
  return (
    <Wrapper>
      <Container>
        <Bar />
      </Container>
    </Wrapper>
  );
};

export default Loader;

const loader = keyframes`
    0%,
    100% {
      transform: translateX(-30px);
    }
    50% {
      transform: translateX(150px);
    }
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  z-index: 9;
  background-color: ${BG_COLOR};

  &::after {
    ${grainPattern}
  }
`;

const Container = styled.div`
  width: 150px;
  height: 3px;
  background-color: ${BORDER_COLOR};
  overflow: hidden;
  position: absolute;
  border-radius: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Bar = styled.div`
  width: 30px;
  height: 3px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff48e6;
  animation: ${loader} 2s infinite;
`;
