import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import Loader from "./Loader";
import Project from "./Project";
import Footer from "./Footer";
import Header from "./Header";
import { BG_COLOR, CONTAINER_WIDTH_MEDIUM } from "./constants";
import { GenerativeToken } from "./types";
import styled from "styled-components";
import { grainPattern } from "./styles";

const basePath = `${process.env.PUBLIC_URL}/videos/`;
export const ASSETS = [
  { id: 26210, video: `${basePath}perpetuum-mobile.mp4` },
  { id: 25598, video: `${basePath}not-your-ordinary-bloodstream.mp4` },
  { id: 23536, video: `${basePath}oculus-prime.mp4` },
  { id: 21264, video: `${basePath}caught-in-the-maelstrom.mp4` },
  { id: 20285, video: `${basePath}supernova-poppy.mp4` },
  { id: 19993, video: `${basePath}isometric-roulette.mp4` },
  { id: 19775, video: `${basePath}exoplanets-in-texture.mp4` },
  { id: 16515, video: `${basePath}motherboard-deluxe.mp4` },
  { id: 15232, video: `${basePath}geometry-tumbler.mp4` },
  { id: 14002, video: `${basePath}disco-planet.mp4` },
  { id: 13000, video: `${basePath}liquid-mirror.mp4` },
];

const GET_PROJECTS = gql`
  query Query($userId: String) {
    user(id: $userId) {
      generativeTokens {
        id
        name
        objktsCount
        metadata
      }
    }
  }
`;

interface GraphResponse {
  user: {
    generativeTokens: GenerativeToken[];
  };
}

const App = () => {
  const { loading, error, data } = useQuery<GraphResponse>(GET_PROJECTS, {
    variables: {
      userId: "tz1PY7E6A3bAzk776PH8j4LvkJmnowNsfruJ",
      take: 50,
    },
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [error]);

  const { generativeTokens } = data?.user || { generativeTokens: [] };

  return (
    <>
      {(!data || loading) && <Loader />}
      <Header />
      <Container isLoading={!data || loading}>
        {generativeTokens.map((token, i) => (
          <Project key={i} {...token} index={i} />
        ))}
      </Container>
      <Footer />
      <Background />
    </>
  );
};

export default App;

const Container = styled.main<{ isLoading: boolean }>`
  padding: 100px 20px 50px 20px;
  width: 100%;
  max-width: ${CONTAINER_WIDTH_MEDIUM}px;
  margin: 0 auto;
  height: ${({ isLoading }) => (isLoading ? "100%" : "auto")};
  overflow: ${({ isLoading }) => (isLoading ? "hidden" : "initial")};
`;

const Background = styled.div`
  background-color: ${BG_COLOR};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  &::after {
    ${grainPattern}
  }
`;
