import styled from "styled-components";
import { getGeneratedUrl } from "./Project";
import { BG_COLOR, SHADOW_COLOR, CONTAINER_WIDTH_SMALL } from "./constants";
import { MetaData } from "./types";
import { useState } from "react";

const Thumbnail = ({
  metadata,
  name,
  index,
  onClick,
}: {
  metadata?: MetaData;
  name: string;
  index: number;
  onClick: () => void;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <ThumbnailContainer isLoaded={isLoaded} index={index} onClick={onClick}>
      {metadata && (
        <ThumbnailImage
          isLoaded={isLoaded}
          src={getGeneratedUrl(metadata.thumbnailUri)}
          alt={metadata.name}
          onLoad={() => setIsLoaded(true)}
        />
      )}
    </ThumbnailContainer>
  );
};

export default Thumbnail;

const ThumbnailContainer = styled.div<{ index: number; isLoaded: boolean }>`
  position: relative;
  pointer-events: ${({ isLoaded }) => (isLoaded ? `auto` : `none`)};
  cursor: pointer;
  display: flex;
  background-color: ${BG_COLOR};
  box-shadow: ${({ index }) =>
    index % 2 === 0
      ? `4px 4px 10px 0px ${SHADOW_COLOR}80`
      : `-4px 4px 10px 0px ${SHADOW_COLOR}80`};
  height: 125px;
  width: 125px;
  border-radius: 5px;

  @media (min-width: ${CONTAINER_WIDTH_SMALL}px) {
    height: 200px;
    width: 200px;
  }
`;

const ThumbnailImage = styled.img<{ isLoaded: boolean }>`
  height: 100%;
  width: 100%;
  opacity: 0;
  border-radius: 5px;
  transition: opacity 0.2s;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;
