import styled from "styled-components";
import {
  BG_COLOR,
  BORDER_COLOR,
  CONTAINER_WIDTH_MEDIUM,
  TEXT_COLOR,
} from "./constants";
import { grainPattern } from "./styles";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <div>
          <List>
            <ListItem>
              <ListLink
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/computeremotion"
              >
                #twitter
              </ListLink>
            </ListItem>
            <ListItem>
              <ListLink
                target="_blank"
                rel="noreferrer"
                href="https://www.fxhash.xyz/u/Daniel%20Gyllensand"
              >
                #fxhash
              </ListLink>
            </ListItem>
            <ListItem>
              <ListLink
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/computeremotion"
              >
                #instagram
              </ListLink>
            </ListItem>
          </List>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  pointer-events: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
`;

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH_MEDIUM}px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding: 0;

  @media (min-width: ${CONTAINER_WIDTH_MEDIUM}px) {
    padding: 0 20px;
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: flex-end;
  margin: 0;
  font-size: 16px;
  list-style: none;
  border: 1px solid ${BORDER_COLOR};
  border-bottom: none;
  background-color: ${BG_COLOR};
  position: relative;
  overflow: hidden;
  padding: 6px 20px;
  pointer-events: auto;
  border-radius: 5px 0 0 0;

  &::after {
    ${grainPattern}
  }

  @media (min-width: ${CONTAINER_WIDTH_MEDIUM}px) {
    padding: 10px 20px;
    border-radius: 5px 5px 0 0;
  }
`;

const ListItem = styled.li`
  margin-top: 1px;
  
  &:not(:first-of-type) {
    margin-left: 10px;
  }
`;

const ListLink = styled.a`
  color: ${TEXT_COLOR};
  text-decoration: none;
`;
